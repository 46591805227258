import React, { lazy } from 'react';
import {
  SettingOutlined,
  DatabaseOutlined,
  FolderOpenOutlined,
  PayCircleOutlined,
  UserOutlined,
  CommentOutlined,
  SafetyOutlined
  
} from '@ant-design/icons';

/**
 * 路由懒加载
 * @param {String} filename 文件路径
 */
const lazyRouter = (filename: string) => {
  return lazy(() => import(`../pages/${filename}`))
}
export const RouteMap = [
  { path: '/', name: 'home', title: '主页', component: lazyRouter('Home/Index'), isMenu: false},
  { path: '/user', name: 'user', title: '用户管理', icon: <UserOutlined />, component: lazyRouter('user/Index'), isMenu: true,},
  { path: '/pay', name: 'pay', title: '赔付管理', icon: <PayCircleOutlined />, component: lazyRouter('pay/Index'), isMenu: true,},
  { path: '', name: 'Product', title: '产品管理', icon: <DatabaseOutlined />, isMenu: true, 
    children: [
      { path: '/Product', name: 'product', title: '产品管理', component: lazyRouter('product/Index'), isMenu: true},
      { path: '/Award', name: 'award', title: '榜单管理',  component: lazyRouter('product/List'), isMenu: true},
    ]
  },
  { path: '/article', name: 'article', title: '文章管理', icon: <FolderOpenOutlined />, component: lazyRouter('article/Index'), isMenu: true}, 
  { path: '', name: 'deploy', title: '配置管理', icon: <SettingOutlined />, isMenu: true,
    children: [
      { path: '/banner', name: 'banner', title: 'bannner配置', component: lazyRouter('configuration/Banner'), isMenu: true},
      { path: '/Content', name: 'content', title: '内容配置',  component: lazyRouter('configuration/Content'), isMenu: true},
      { path: '/Index', name: 'index', title: '首页配置',  component: lazyRouter('configuration/Index'), isMenu: true},
      { path: '/Parameter', name: 'parameter', title: '参数配置',  component: lazyRouter('configuration/Parameter'), isMenu: true},
      { path: '/Label', name: 'label', title: '标签管理',  component: lazyRouter('configuration/Label'), isMenu: true},
    ]
  },
  { path: '', name: 'permission', title: '权限管理', icon: <SafetyOutlined />, isMenu: true,
    children: [
      {path: '/account', name: 'account', title: '账号管理', component: lazyRouter('authority/Account'), isMenu: true},
      {path: '/role', name: 'role', title: '角色管理', component: lazyRouter('authority/Role'), isMenu: true},
      {path: '/Consultant', name: 'consultant', title: '顾问列表', component: lazyRouter('authority/Consultant'), isMenu: true}
    ]
  },
  { path: '/work', name: 'work', title: '工作台', icon: <CommentOutlined />, component: lazyRouter('work/Index'), isMenu: true,}
]

let routeList: any[] = []
let neatenRouteMap = (list: any[]) => {
  list.forEach((item: any) => {
    if (item.children && item.children.length) {
      neatenRouteMap(item.children)
    } else {
      routeList.push(item)
    }
  })
}
neatenRouteMap(RouteMap)

export const RouteList = routeList
