import React from 'react';
import { useHistory } from 'react-router-dom'
import './login.css'
import { Form, Input, Button, message} from 'antd'
import { useStore } from '../../store/context'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { SET_TOKEN, IS_ROOT, MENU_PERMIS, OPERATE_PERMIS, IS_LOGIN,USERNAME } from '../../store/actionTypes'
import { login } from '../../fetch/api';

const Login: React.FC<any> = () => {
  const history = useHistory()
  const [, dispatch] = useStore()
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };
  // const tailLayout = {
  //   wrapperCol: { offset: 6, span: 16 },
  // }
  const onFinish = (values: any) => {
    login(values).then((res: any) => {
      if (res.status_code === 200) {
        sessionStorage.removeItem('tags')
        dispatch({type: IS_LOGIN, value: true})
        dispatch({type: SET_TOKEN, value: res.data.token})
        dispatch({type: IS_ROOT, value: res.data.user.is_root})
        dispatch({type: MENU_PERMIS, value: res.data.user.menu_permissions})
        dispatch({type: OPERATE_PERMIS, value: res.data.user.operate_permissions})
        dispatch({type: USERNAME, value: res.data.user.name})
        history.push('/')
      } else {
        console.log(res)
        message.error(res.message)
      }
    }).catch((err: any) => {
      console.log(err)
      message.error(err.message)
    })

  }
  return (
    <div className="login">
      <div className="form_box">
        <div className="welcome_text">您好~</div>
        <div className="welcome">欢迎登录后台管理系统</div>
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            label=""
            name="username"
            rules={[{ required: true, message: '请输入用户名', whitespace: true }]}
          >
            <Input placeholder="用户名"
              prefix={<UserOutlined style={{color: '#008AE8', fontSize: '22px'}}/>}
             maxLength={18}>

            </Input>
          </Form.Item>
          <Form.Item
            label=""
            name="password"
            rules={[{ required: true, message: '请输入密码', whitespace: true }]}
          >
            <Input.Password placeholder="账户密码"
            prefix={<LockOutlined style={{color: '#008AE8', fontSize: '22px'}}/>}>
            </Input.Password>
          </Form.Item>
          {/* <Form.Item {...tailLayout}> */}
            <Button type="primary" htmlType="submit" className="subimt_btn">登录</Button>
          {/* </Form.Item> */}
        </Form>
      </div>
    </div>
  )
}

export default Login