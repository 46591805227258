import axios from 'axios'
import qs from 'qs';
import { message } from 'antd';
import { base_url } from '../utils/url'

const Axios = axios.create({
  baseURL: base_url,
  timeout: 15000 // 请求超时时间
})

let pending: any[] = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let cancelToken = axios.CancelToken;
let removePending = (config: { url: string; method: string; }) => {
  for (let p in pending) {
    if (pending[p].u === config.url + '&' + config.method) { //当当前请求在数组中存在时执行函数体
      pending[p].f(); //执行取消操作
      pending.splice(Number(p), 1); //把这条记录从数组中移除
    }
  }
}
// 添加请求拦截器
Axios.interceptors.request.use((config: any) => {
  // 在发送请求之前做些什么
  let method = config.method.toUpperCase()
  let type:Boolean = false
  if (method === 'GET' || method === 'DELETE' || method === 'delete') {
    type = JSON.stringify(config.params) === localStorage.lastParams ? true : false
  } else {
    type = JSON.stringify(config.data) === localStorage.lastData ? true : false
  }
  config.headers['Authorization'] = `Bearer ${sessionStorage.token}`
  if (config.url.includes('export')) {
    config.responseType = 'blob'
  }

  removePending(config); //在一个ajax发送前执行一下取消操作
  config.cancelToken = new cancelToken((c) => {
    // 这里的ajax标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
    pending.push({ u: config.url + '&' + config.method, f: c });
  });
  return config;
}, (error: any) => {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
Axios.interceptors.response.use((response: any) => {
  // 对响应数据做点什么
  removePending(response.config); // 在一个ajax响应后再执行一下取消操作，把已经完成的请求从pending中移除
  if (response.data.code === 401) {
    message.error(response.data.message) 
    window.location.href = '/login'
  } else if (response.data.code === 403) {
    message.error(response.data.message) 
    window.location.href = '/noPermissions'
  } else {
    return response.data
  }
}, (error: any) => {
    return Promise.reject(error)
})

// 发起请求
export const fetch = (url: any, data: any, method = `GET`, file?: Boolean) => {
  data = data || ''
  if (method === `GET` || method === `get`) {
    return Axios.get(url, data.params ? data : {
      params: data
    })
  }
  if (method === 'DELETE' || method === 'delete') {
    return Axios.delete(url, data.params ? data : {
      params: data
    })
  }
  if (method === 'POST' || method === 'post') {
    if (file) {
      return Axios.post(url, data)
    } else {
      return Axios.post(url, qs.stringify(data))
    }
  }
  if (method === 'PATCH' || method === 'patch') {
    return Axios.patch(url, qs.stringify(data))
  }
  if (method === 'PUT' || method === 'put') {
    return Axios.put(url, qs.stringify(data))
  }
}
