const initialState = {
  isLogin: sessionStorage.isLogin ? JSON.parse(sessionStorage.isLogin) : false,
  tags: sessionStorage.tags ? JSON.parse(sessionStorage.tags) : [],
  token: sessionStorage.token ? sessionStorage.token : null,
  isRoot: sessionStorage.isRoot ? Number(sessionStorage.isRoot) : 0, // 是否为超管 1 是  0 否
  userName:sessionStorage.userName ? sessionStorage.userName : '',
  menuPermissions: sessionStorage.menuPermissions ? JSON.parse(sessionStorage.menuPermissions) : [], // 菜单权限
  operatePermissions: sessionStorage.operatePermissions ? JSON.parse(sessionStorage.operatePermissions) : [], // 按钮权限
}

export default initialState